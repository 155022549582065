<template>
  <div>正在跳转...</div>
</template>

<script>
import { go } from '@/service/navigate'
export default {
  mounted() {
    const { url } = this.$route.query
    if (url) {
      go(url)
    } else {
      this.$popErrorPage('跳转链接不能为空')
    }
  },
}
</script>

<style></style>
